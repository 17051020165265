import { service } from '../CONF.js'

//首页轮播图
export function getLunbotu() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result){
            return result.get('/main/home/banner',{
                params: {
                    authFree: true
                }
            })
        }))
    })
}

//前台三方显示
export function getListStatus() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result){
            return result.get('/main/home/thirdMenu',{
                params: {
                    
                }
            })
        }))
    })
}