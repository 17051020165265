<script>
    import $ from 'jquery';    
    import { cloudImageBaseUrl,headerMenu, verse } from '@/CONF.js'

    import { getLunbotu, getListStatus } from "@/api/home_page";
    import { issue, bulletin, notice, registerGold, getGold, room_list, caizhong, getLotteries, userBinding, casinoLogin, gamesLink, sexyCasinoLogin, asCasinoLogin, dgCasinoLogin, getThirdPartyList, bgCasinoLogin, gjCasinoLogin, kyCasinoLogin, dsCasinoLogin, lcCasinoLogin, xaCasinoLogin, kgCasinoLogin, ogCasinoLogin, agCasinoLogin, aecCasinoLogin, bbinCasinoLogin, sbCasinoLogin, ggCasinoLogin, agsCasinoLogin } from "@/api/home";
    import { register_wx } from '@/api/login';
    
    import { account_balance, getAppRedPack, openAppRedPack } from '@/api/beijing28'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    import 'swiper/dist/css/swiper.css'
    import axios from 'axios'

    export default {
        name:'Home',
        components: {
            swiper,
            swiperSlide,
            Header: () => import('components/base/Header'),        
            RedEnvelopeRule: () => import('views/my/red_envelope/RedEnvelopeRule'),
        },
        data() {
            var currentTemplate=this._TEMPLATE;
            var currentOssPath="//cpwap."+process.env.VUE_APP_PROD_DOMAIN+"/";
            return {                
                user:null,
                url_head:['param', 'value', 'select', 'record', 'revert'],
                url_tail:['check', 'display', 'error', 'frequency', 'memory'],
                openid_head:['receive', 'source', 'stack', 'temporary', 'buffer'],
                openid_tail:['token', 'auth', 'control', 'destination', 'environment'],
                httpY:['h'],
                urlType:false,
                cloudImageBaseUrl,
                cloudBaseFolder:currentTemplate,
                imgUrlTitle: currentOssPath,
                notice: '',
                verse,
                lunbotuList: [],
                czList: null,
                sxList:[],
                qpList:[],
                yyList:[],
                tyList:[],
                redPack_interval: null,
                swiperOption: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                  centeredSlides: true,
                  autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                  },
                  pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                  },
                },
                header:{
                    mainTitle:'',
                    headerMenu:false,
                    backButton:false,
                    logoUrl:null,
                },
                featured:[
                    {
                        title: getWord('trend2'),
                        url:'/AllDrawingResult',
                        iconUrl:cloudImageBaseUrl+currentTemplate+'/home/featured/trend.png'
                    },
                    {
                        title: getWord('check_in_lucky_draw2'),
                        url:'/action/singIn',
                        iconUrl:cloudImageBaseUrl+currentTemplate+'/home/featured/prize.png'
                    },
                    {
                        title: getWord('lucky_draw'),
                        url:'/action/luck_draw',
                        iconUrl:cloudImageBaseUrl+currentTemplate+'/home/featured/bonus.png'
                    },
                    {
                        title: getWord('app_download'),
                        url:'/action/down',
                        iconUrl:cloudImageBaseUrl+currentTemplate+'/home/featured/download.png'
                    }
                ],
                featured2:[
                    {
                        title: getWord('trend2'),
                        url:'/AllDrawingResult',
                        iconUrl:currentOssPath+currentTemplate+'/home/featured/trend.png'
                    },
                    {
                        title: getWord('check_in_lucky_draw3'),
                        url:'/action/singIn',
                        iconUrl:currentOssPath+currentTemplate+'/home/featured/prize.png'
                    },
                    {
                        title: getWord('lucky_draw'),
                        url:'/action/luck_draw',
                        iconUrl:currentOssPath+currentTemplate+'/home/featured/bonus.png'
                    },
                    {
                        title: getWord('app_download'),
                        url:'/action/down',
                        attachedIconUrl:currentOssPath+currentTemplate+'/home/featured/download-icon.png',
                        iconUrl:currentOssPath+currentTemplate+'/home/featured/download.png'
                    }
                ],
                featured3:[
                    {
                        title: getWord('recharge_and_withdrawal'),
                        url:'/p',
                        iconUrl:currentOssPath+currentTemplate+'/home/featured/withdraw.png',
                        color: '#EDB93F',
                    },
                    {
                        title: getWord('check_in_lucky_draw3'),
                        url:'/action/singIn',
                        iconUrl:currentOssPath+currentTemplate+'/home/featured/prize.png',
                        color: '#FF0000',
                    },
                    {
                        title: getWord('lucky_draw'),
                        url:'/action/luck_draw',
                        iconUrl:currentOssPath+currentTemplate+'/home/featured/bonus.png',
                        color: '#3C8EFB',
                    },
                    {
                        title: getWord('sale_event'),
                        url:'/discount',
                        iconUrl:currentOssPath+currentTemplate+'/home/featured/sales.png',
                        color: '#52B653',
                    }
                ],
                gameTypeList:[
                    {
                        title: getWord('caipiaoyouxi'),
                        icon:'icon-caiquan',
                        current:true,
                        show:true,
                    },
                    {
                        title: getWord('zhenrenshixun'),
                        icon:'icon-shixun',
                        current:false,
                        show:false,
                    },
                    {
                        title: getWord('qipaiyouxi'),
                        icon:'icon-qipai',
                        current:false,
                        show:false,
                    },
                    {
                        title: getWord('dianziyouyi'),
                        icon:'icon-youyi',
                        current:false,
                        show:false,
                    },
                    {
                        title: getWord('tiyusaishi'),
                        icon:'icon-tiyu',
                        current:false,
                        show:false,
                    },
                ],

                //template-2 begin
                appMessageClosed:true,
                swiperOption2: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                  centeredSlides: true,
                  autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                  },
                  pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: false
                  },
                },
                
                gameList:{
                    sxBgUrl1:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-1.png',
                        color:'#4393ff',
                    },
                    sxBgUrl2:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-2.png',
                        color:'#de2f2b',
                    },
                    sxBgUrl3:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-3.png',
                        color:'#4393ff'
                    },
                    sxBgUrl4:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-4.png',
                        color:'#de2f2b',
                    },
                    sxBgUrl5:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-5.png',
                        color:'#FFA056',
                    },
                    sxBgUrl6:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-6.png',
                        color:'#B39FFF',
                    },
                    sxBgUrl7:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-7.png',
                        color:'#B39FFF',
                    },
                    sxBgUrl8:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-8.png',
                        color:'#B39FFF',
                    },
                    sxBgUrl9:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-9.png',
                        color:'#B39FFF',
                    },
                    sxBgUrl10:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-10.png',
                        color:'#B39FFF',
                    },
                    sxBgUrln:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-n.png',
                        color:'#999999',
                    },

                    tyBgUrl1:{
                        img:currentOssPath+currentTemplate+'/home/game-list/ty-bg-1.png',
                        color:'#de43ff',
                    },
                    tyBgUrl2:{
                        img:currentOssPath+currentTemplate+'/home/game-list/ty-bg-2.png',
                        color:'#B676CA',
                    },
                    tyBgUrl3:{
                        img:currentOssPath+currentTemplate+'/home/game-list/ty-bg-3.png',
                        color:'#0381E2',
                    },
                    tyBgUrl4:{
                        img:currentOssPath+currentTemplate+'/home/game-list/ty-bg-4.png',
                        color:'#F6A730',
                    },
                    tyBgUrl5:{
                        img:currentOssPath+currentTemplate+'/home/game-list/ty-bg-5.png',
                        color:'#1435d9',
                    },
                    tyBgUrln:{
                        img:currentOssPath+currentTemplate+'/home/game-list/sx-bg-n.png',
                        color:'#999999',
                    },

                    yyBgUrl1:{
                        img:currentOssPath+currentTemplate+'/home/game-list/yy-bg-1.png',
                        color:'#FFC829',
                    },
                    yyBgUrl2:{
                        img:currentOssPath+currentTemplate+'/home/game-list/yy-bg-2.png',
                        color:'#FF9127',
                    },
                    yyBgUrl3:{
                        img:currentOssPath+currentTemplate+'/home/game-list/yy-bg-3.png',
                        color:'#4EC22E',
                    },
                    yyBgUrl4:{
                        img:currentOssPath+currentTemplate+'/home/game-list/yy-bg-4.png',
                        color:'#FF7777',
                    },
                    yyBgUrl5:{
                        img:currentOssPath+currentTemplate+'/home/game-list/yy-bg-5.png',
                        color:'#0381E2',
                    },
                    yyBgUrln:{
                        img:currentOssPath+currentTemplate+'/home/game-list/yy-bg-n.png',
                        color:'#999999',
                    },
                },
                gameTypeList2:[
                    {
                        title: getWord('caipiaoyouxi'),
                        icon:'icon-caiquan',
                        current:true,
                        show:true,
                        type: '0',
                    },
                    {
                        title:getWord('asia_games'),
                        icon:'icon-caiquan',
                        current:true,
                        show:false,
                    },
                    {
                        title: getWord('qipaiyouxi'),
                        icon:'icon-qipai',
                        current:false,
                        show:false,
                    },
                    {
                        title: getWord('zhenrenshixun'),
                        icon:'icon-shixun',
                        current:false,
                        show:false,
                    },
                    {
                        title: getWord('tiyusaishi'),
                        icon:'icon-tiyu',   
                        current:false,
                        show:false,
                    },
                    {                    
                        title: getWord('dianziyouyi'),
                        icon:'icon-youyi',
                        current:false,
                        show:false,
                    },
                ],
                //template-2 end

                redPackData:{
                    showTime:null,
                    hideTime:null,

                    startTime:null,
                    endTime:null,

                    id:null,

                    earnedAmount:null,
                    errorMsg:null,
                    errorMsgId:null,

                    ruleConditions: null,
                },
                ruleShow: false,

                pc28Time:{
                    'bj28':{
                        current:null,
                        last:null,
                        closed:null,
                    },
                    'jnd28':{
                        current:null,
                        last:null,
                        closed:null,
                    },
                    'twbg28':{
                        current:null,
                        last:null,
                        closed:null,
                    },
                    'mgelg28':{
                        current:null,
                        last:null,
                        closed:null,
                    },
                    'jndx28':{
                        current:null,
                        last:null,
                        closed:null,
                    },
                    'snfk28':{
                        current:null,
                        last:null,
                        closed:null,
                    },
                }
            };
        },
        methods: {
            showRule(){
                this.ruleShow=true;
            },
            closeRedPack(){
                this.redPackData.earnedAmount=null;
                this.redPackData.errorMsg=null
                this.redPackData.errorMsgId=null
            },
            openRedPack(){
                openAppRedPack({
                    id:this.redPackData.id
                }).then(result=>{
                    if (result.data.code == "SUCCESS") {
                        this.redPackData.earnedAmount=result.data.result.money;
                    }else {
                        this.redPackData.errorMsg=result.data.msg
                        this.redPackData.errorMsgId=result.data.code
                        // Toast(result.data.msg);
                    }
                })
            },
            timeFilter(time){
                var currentTime=new Date().getTime();

                var diff=Math.abs(time-currentTime)

                return ( Math.floor(diff/(1000*60*60*24))>0?Math.floor(diff/(1000*60*60*24))+'天 ':'' )+
                       ( Math.floor(new String(diff/(1000*60*60*24)).split('.')[1].slice(0,2)*24/100)<10?'0'+Math.floor(new String(diff/(1000*60*60*24)).split('.')[1].slice(0,2)*24/100):Math.floor(new String(diff/(1000*60*60*24)).split('.')[1].slice(0,2)*24/100) )+
                       ':'+
                       ( Math.floor(new String(diff/3600000).split('.')[1].slice(0,2)*60/100<10)
                                    ?'0'+Math.floor(new String(diff/3600000).split('.')[1].slice(0,2)*60/100)
                                    :Math.floor(new String(diff/3600000).split('.')[1].slice(0,2)*60/100) )+
                       ':'+
                       ( parseInt((diff/1000)%60)<10?'0'+parseInt((diff/1000)%60):parseInt((diff/1000)%60) )
            },
            //判定取链接哪一个参数,arr为约定固定参数
            forUrl(url,arr){
                for(let y=0;y<url.length;y++){
                    for(let x=0;x<arr.length;x++){
                        if(url[y].indexOf(arr[x])>-1){
                            return url[y].split('=')[1];
                        }
                    }
                }
            },

            hidden() {
                app.Mint.Toast(getWord('coming_soon'));
            },
            
            getWMurl(item) {
                if (!self.localStorage.getItem('user')) {
                    this.$router.push('/login')
                    return false;
                }
                if (item.parent==='youyi') {
                    this.$router.push({
                        path:'/ThirdpartyList',
                        query: {
                            mainTitle: item.remark,
                            from: item.name,
                        }
                    })
                }
                if(item.name==='SboCasino'||item.name==='SaCasino'){
                    casinoLogin(item.name.split('Casino')[0].toLowerCase()).then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url) {
                            window.location.href = result.data.result.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='WmCasino'){
                    gamesLink().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.link) {
                            window.location.href = result.data.result.link;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='sexyCasino'){
                    sexyCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url) {
                            window.location.href = result.data.result.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='asCasino'){
                    asCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url) {
                            window.location.href = 'http://'+result.data.result.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='DgCasino'){
                    dgCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url.data.url) {
                            window.location.href = result.data.result.url.data.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='BgCasino'){
                    bgCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url.result) {
                            window.location.href = result.data.result.url.result;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='CsCasino'){
                    gjCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url) {
                            window.location.href = result.data.result.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='KyCasino'){
                    kyCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.d.url) {
                            window.location.href = result.data.result.d.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='DsCasino'){
                    dsCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url) {
                            window.location.href = result.data.result.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='LcCasino'){
                    lcCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.d.url) {
                            window.location.href = result.data.result.d.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='XaCasino'){
                    xaCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.link) {
                            window.location.href = result.data.result.link;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='KgCasino'){
                    kgCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.data) {
                            window.location.href = result.data.result.data;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='OgCasino'){
                    ogCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url) {
                            window.location.href = result.data.result.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='AgCasino'){
                    agCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url.data) {
                            window.location.href = result.data.result.url.data;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='SexyAecCasino'){
                    aecCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url.url) {
                            window.location.href = result.data.result.url.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='BbinCasino'){
                    bbinCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url) {
                            window.location.href = result.data.result.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='SbCasino'){
                    sbCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url) {
                            window.location.href = result.data.result.url;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
                if(item.name==='AgsCasino'){
                    agsCasinoLogin().then(result => {
                        if (result.data.code == "SUCCESS" && result.data.result.url.data) {
                            window.location.href = result.data.result.url.data;
                        } else {
                            app.Mint.Toast(result.data.msg);
                            return;
                        }
                    })
                }
            },

            routerLink(item) {
                if (this[item.type+'Closed']) {
                    return false;
                }
                
                if (item.type==='more-cz') {
                    console.log('redirect to more cz list')
                    this._linkTo({
                        path:'GameList',
                    });
                    return false;
                }
                if(item.type!='lhc'){
                    if ((item.parent==='systematic' || item.parent==='dt_classic' || item.parent==='asia')  && (item.parent!=='dt_pc28'&&item.type!=='bjsc'&&item.type!=='xyft'&&item.type!=='cqssc')) {
                        this._linkTo({
                            path:'/t',
                            query: {
                                type: item.type,
                                name: item.name
                            }
                        })
                    }else {
                        this._linkTo({
                            path:'beijing28/room_list',
                            query: {
                                type: item.type,
                                name: item.name
                            }
                        })
                    }
                }else{
                    this._linkTo({
                        path:'/t',
                        query: {
                            type: 'lhc',
                            name: '香港六合彩'
                        }
                    })
                }
            },
            
            noticeLike() {
                this.$router.push('/my/news/news');
            },
            
            getNotice() {
                notice().then(result => {
                    if (result.data.code == "SUCCESS" && result.data.result) {
                        result.data.result.forEach(item=>{
                            this.notice += item.content + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
                        })
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                })
            },        
            
            fetchCarousel() {
                getLunbotu().then(result => {
                    if (result.data.code == "SUCCESS") {
                        this.lunbotuList = result.data.result;
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                })
            },

            fetchGameList() {
                var that=this;
                var list=this._TEMPLATE==='template-1'||this._TEMPLATE==='template-3'
                            ?this.gameTypeList
                            :this.gameTypeList2;
                getListStatus().then(result=>{
                    if (result.data.code == "SUCCESS") {
                        if ( window.localStorage.getItem("maintitle") ) {
                            this.header.mainTitle=window.localStorage.getItem("maintitle");
                            this.header.logoUrl=self.localStorage.getItem('logourl')
                        }else {
                            console.log('matinTitle get failed.')
                        }
                        var data=result.data.result[0]
                        if (data.view_status===1) {
                            var related=list.filter(item=>{
                                return item.icon.indexOf('shixun')>-1
                            })[0]
                            related.show=true;
                            getThirdPartyList(1).then(result=>{
                                result.data.result.forEach(item=>{
                                    item.parent='shixun'
                                    item.img= this.imgUrlTitle + this.cloudBaseFolder + item.img
                                })
                                this.sxList=result.data.result
                            })
                        }
                        if (data.chess_status===1) {
                            var related=list.filter(item=>{
                                return item.icon.indexOf('qipai')>-1
                            })[0]
                            related.show=true;
                            getThirdPartyList(2).then(result=>{
                                this.qpList=result.data.result
                            })
                        }
                        if (data.elesport_status===1) {
                            var related=list.filter(item=>{
                                return item.icon.indexOf('youyi')>-1
                            })[0]
                            related.show=true;
                            getThirdPartyList(3).then(result=>{
                                result.data.result.forEach(item=>{
                                    item.parent='youyi'
                                })
                                this.yyList=result.data.result
                            })
                        }
                        if (data.sport_status===1) {
                            var related=list.filter(item=>{
                                return item.icon.indexOf('tiyu')>-1
                            })[0]
                            related.show=true;
                            getThirdPartyList(4).then(result=>{
                                result.data.result.forEach(item=>{
                                    item.parent='tiyu'
                                   item.img= this.imgUrlTitle + this.cloudBaseFolder + item.img
                                })
                                this.tyList=result.data.result
                            })
                        }
                        if (data.sport_status!==1&&data.elesport_status!==1&&data.chess_status!==1&&data.view_status!==1) {
                            this.$store.commit('UPDATE_thirdMenu',false)
                        }
                        
                        caizhong().then(result => {
                            if (result.data.code == "SUCCESS") {
                                this.czList=[];
                                var list=[]
                                var data=result.data.result.numbers.children

                                Object.keys(data).forEach( (key,index)=>{
                                    if (!list[index]){
                                        list[index]={};
                                        list[index].list=[];
                                        list[index].type=key;
                                        list[index].name=data[key].name;
                                    }
                                    data[key].children.forEach(item=>{
                                        item.img=this.imgUrlTitle + this.cloudBaseFolder + '/home/game-list/' + item.type + '.png';
                                        list[index].list.push(item)
                                    })
                                })
                                data=list

                                if (this._TEMPLATE==='template-2') {
                                    this.$set(this.czList,0,data[0])
                                    var _formatedData=data.filter(item=>{
                                        if (item.type==='dt_classic'||item.type==='systematic') {
                                            return item.list
                                        }
                                    })

                                    if (_formatedData.length>0) {
	                                    this.$set(that.czList,1,{
	                                        list:_formatedData[1]
	                                                    ?_formatedData[0].list.concat(_formatedData[1].list)
	                                                    :_formatedData[0].list
	                                    })
	                                }
                                    if (data[3]) {
                                        this.$set(that.czList,2,
                                            data[3]
                                        )
                                    }
                                    var related=list.filter(item=>{
                                        return item.type==='asia'
                                    })
                                    if(related.length) {
                                        this.gameTypeList2[1].show=true;
                                    }else {
                                        this.gameTypeList2[1].show=false;
                                    }
                                }else {

                                    if (this._TEMPLATE==='template-3') {                                    
                                        getLotteries().then(result=>{
                                            if (result.data.code==='SUCCESS') {
                                                var get_lotteries_data=result.data.result.game;
                                                get_lotteries_data.forEach(item=>{
                                                    item.img=this.imgUrlTitle + this.cloudBaseFolder + '/home/game-list/' + item.type + '.png';
                                                })
                                                this.$set(this.czList,0,{})
                                                this.$set(this.czList[0],'list',get_lotteries_data)
                                            }else {
                                                app.Mint.Toast(result.data.msg);
                                            }
                                        })
                                    }else {
                                        data.forEach( (item,index)=>{
                                            this.$set(this.czList,index,data[index])        
                                        })
                                    }
                                }

                                if (_TEMPLATE!=='template-3' && this.czList[0].list) {
                                    this.czList[0].list.forEach(item=>{
                                        this.pc28Timer(item.type)

                                        room_list(item.type).then(_result => {
                                            if (_result.data.code === 'SUCCESS') {
                                                var data=_result.data.result;
                                                var opend=data.filter(item=>{
                                                    return item.status===1
                                                })
                                                if (!opend[0]) {
                                                    this.pc28Time[item.type].closed=true;
                                                }else {
                                                    this.pc28Time[item.type].closed=false;
                                                }
                                            }
                                        })
                                    })                                    
                                }

                                if (this.$refs.gameType && this.$refs.gameType.id) {
                                    this.CustomScroll(this.$refs.gameType.id);
                                }
                            } else {
                                app.Mint.Toast(result.data.msg);
                            }
                        })
                    }else {
                        app.Mint.Toast(getWord('api_error1'))
                    }
                })
            },
            formatCountdownTimer(timeStamp){
                if (isNaN(timeStamp)) {
                    return 'ERROR: invalid countdown format'
                }else if (timeStamp<0 || !timeStamp) {
                    return getWord('handicap_renew')
                } else {
                    var hours=parseInt(timeStamp/3600)
                    var minutes=parseInt(timeStamp%3600/60)
                    var seconds=parseInt(timeStamp%3600%60)
                    return (hours>9?hours:'0'+hours)+':'+(minutes>9?minutes:'0'+minutes)+':'+(seconds>9?seconds:'0'+seconds)
                }
            },
            checkWx() {
                var that=this;

                this.openPopup(1)

                let url = unescape(window.location.href);
                let x = '';

                for(let i=0;i<this.url_head.length;i++){
                    if(url.indexOf(this.url_head[i])>-1){
                        this.urlType = true;
                    }
                }
                if(this.urlType){
                    url = url.split('?')[1];
                    url = url.split('&');
                    this.url_head = this.forUrl(url,this.url_head);
                    this.url_tail = this.forUrl(url,this.url_tail);
                    this.openid_head = this.forUrl(url,this.openid_head);
                    this.openid_tail = this.forUrl(url,this.openid_tail);
                    this.httpY = this.forUrl(url,this.httpY);
                    if(this.httpY == 'y'){
                        this.httpY = 'https://';
                    }else if(this.httpY == 'n'){
                        this.httpY = 'http://';
                    }
                    x = this.httpY + this.url_head + '.' + this.url_tail;
                    window.localStorage.setItem('apiurl',x);
                    window.localStorage.setItem('openid',this.openid_head+this.openid_tail);

                    console.log('user info removed by wx_login.')
                    window.localStorage.removeItem('user');
                    
                    var formData=new FormData();
                    formData.append('openid',this.openid_head+this.openid_tail)
                    register_wx(formData).then(result => {
                        if (result.data.code === 'SUCCESS') {
                                let user = {
                                token: result.data.result.token,
                                nickname: result.data.result.nickname,
                                recommend:result.data.result.recommend,
                                headImage:result.data.result.headImage,
                                username:result.data.result.username,
                                type:result.data.result.type,
                                wsurl:result.data.result.ws_url
                                };
                                that.user=user;
                            	window.localStorage.setItem('user', JSON.stringify(user));
                                this.getGrade();
                                window.urlType=null;
                                window.connect({
                                    reconnect:true
                                },function(){
                                    window.urlType=true;
                                });
                                console.log('wx ready to connect ws with TOKEN.')
                                this.$nextTick(function(){
                                    if (that.$refs.header2) {
                                        that.$refs.header2.user.username=user.username;
                                        account_balance().then(result => {
                                            if (result.data.code === 'SUCCESS') {
                                                window.localStorage.setItem('user_balance',result.data.result.money);
                                                that.$refs.header2.user.balance=window.localStorage.getItem('user_balance');   
                                            }
                                        })
                                        that.getGrade();
                                    }
                                })                               
                                
                            if(result.data.result.has_first_money == 1){
                                    that.setNewbiePrize();
                                }else{
                                    that.closePopup(1)
                                    
                                let username = JSON.parse(window.localStorage.getItem("user")) && JSON.parse(window.localStorage.getItem("user")).username;
                                    if (!username) {
                                        that.closePopup(1)
                                        that.openPopup(3)
                                    }else {
                                        that.closePopup(1)
                                        that.notifications().get()
                                    }
                                }
                        }else{
                            app.Mint.Toast(result.data.msg);
                        }
                    })
                }else{
                    let username = window.localStorage.getItem("user") && JSON.parse(window.localStorage.getItem("user")) && JSON.parse(window.localStorage.getItem("user")).username;
                    if (!window.localStorage.getItem('openid') && window.localStorage.getItem('openid')!=='anonymous') {
                        
                        if (!username && localStorage.getItem('token')) {
                            that.closePopup(1)
                            that.openPopup(3)
                        }else {
                            (console.log('welcome dude.'),that.closePopup(1))
                        }
                    }else {
                        if (!username) {
                            that.closePopup(1)
                            that.openPopup(3)
                        }else {
                            this.closePopup(1)
                        }
                    }

                    this.header.mainTitle=window.localStorage.getItem("maintitle");
                    this.header.logoUrl=self.localStorage.getItem('logourl');
                    
                    if (!self.localStorage.getItem('user')) {
                        this.notifications().get()
                        
                    }else {
                        if (self.localStorage.getItem('system-announcement-done')==="true") {

                        }else {
                            self.localStorage.setItem('system-announcement-done',true)
                            this.notifications().get()
                        }

                        this.header.mainTitle=window.localStorage.getItem("maintitle");
                        this.header.logoUrl=self.localStorage.getItem('logourl')
                    }
                }
            },

            handleGameType(item){
                var list=this._TEMPLATE==='template-1'||this._TEMPLATE==='template-3'
                            ?this.gameTypeList
                            :this.gameTypeList2;
                var actived=list.filter(item=>{
                    return item.current===true
                })
                if (actived.length>0) {
                    actived.forEach(item=>{
                        item.current=false
                    })
                }
                item.current=true;
                this.$nextTick(() => {
                    $('#game-type').stop(false,false).animate({
                        scrollLeft:(document.getElementsByClassName('active')[0].offsetLeft/2)
                    },100)
                });            
            },

            renderBg(item,index) {
                if (index>10) {return false}
                if (item.parent && item.parent.toLowerCase().indexOf('pc28')>-1) {
                    
                        return { backgroundImage: 'url('+this.imgUrlTitle+this.cloudBaseFolder+'/home/game-list/'+item.type+'-bg.png' }
                    
                }

                if (item.parent && item.parent.toLowerCase().indexOf('shixun')>-1) {
                    if (this.gameList['sxBgUrl'+index]) {
                        return { 
                            backgroundImage: 'url('+this.gameList['sxBgUrl'+index].img,
                            '--data-color':this.gameList['sxBgUrl'+index].color,
                        }
                    }
                }

                if (item.parent && item.parent.toLowerCase().indexOf('tiyu')>-1) {
                    if (this.gameList['tyBgUrl'+index]) {
                        return { 
                            backgroundImage: 'url('+this.gameList['tyBgUrl'+index].img,
                            '--data-color':this.gameList['tyBgUrl'+index].color,    
                        }
                    }
                }

                if (item.parent && item.parent.toLowerCase().indexOf('youyi')>-1) {                    
                    if (this.gameList['yyBgUrl'+index]) {
                        return { 
                            backgroundImage: 'url('+this.gameList['yyBgUrl'+index].img, 
                            '--data-color':this.gameList['yyBgUrl'+index].color,
                        }
                    }
                }
            },

            renderBgN(item){
                if (item.list && item.list[0].parent && item.list[0].parent.toLowerCase().indexOf('pc28')>-1) {
                    
                        return { 
                            backgroundImage: 'url('+this.gameList['sxBgUrln'].img,
                        }
                    
                }

                if (item.parent && item.parent.toLowerCase().indexOf('shixun')>-1) {
                    if (this.gameList['sxBgUrln']) {
                        return { 
                            backgroundImage: 'url('+this.gameList['sxBgUrln'].img,
                            '--data-color':this.gameList['sxBgUrln'].color,
                        }
                    }
                }

                if (item.parent && item.parent.toLowerCase().indexOf('tiyu')>-1) {
                    if (this.gameList['tyBgUrln']) {
                        return { 
                            backgroundImage: 'url('+this.gameList['tyBgUrln'].img,
                            '--data-color':this.gameList['tyBgUrln'].color,   
                        }
                    }
                }

                if (item.parent && item.parent.toLowerCase().indexOf('youyi')>-1) {
                    if (this.gameList['yyBgUrln']) {
                        return { 
                            backgroundImage: 'url('+this.gameList['yyBgUrln'].img,
                            '--data-color':this.gameList['yyBgUrln'].color, 
                        }
                    }
                }
            },

            fetchImagePath(item){
                    return item.img
            },

            fetchGameIconPath(item) {
                    return item.img
            },

            pc28Timer(type){
                var that=this;
                if (!this.currentDrawing) {
                    setTimeout(function(){
                        that.pc28Timer(type);
                    },1000)
                    return false;
                }
                if (this.currentDrawing[type] && this.currentDrawing[type].time_diff && !this.pc28Time[type].current) {
                    this.pc28Time[type].last=this.pc28Time[type].current=this.currentDrawing[type].time_diff;
                    setTimeout(function(){
                        that.pc28Timer(type);
                    },1000)
                }else if (this.currentDrawing[type] && this.currentDrawing[type].time_diff !== this.pc28Time[type].last) {
                    this.pc28Time[type].last=this.pc28Time[type].current=this.currentDrawing[type].time_diff;
                    setTimeout(function(){
                        that.pc28Timer(type);
                    },1000)
                }else {
                    setTimeout(function(){
                        that.pc28Time[type].current-=1;
                        that.pc28Timer(type);
                    },1000)
                }
            },
        },    
        created() {
            var that=this;
            var list=this._TEMPLATE==='template-1'||this._TEMPLATE==='template-3'
                            ?this.gameTypeList
                            :this.gameTypeList2;
            this.checkWx();
        },
        inject: [
            'popupIndex',
            'openPopup',
            'closePopup',
            'notifications',
            'gethongbao',
            'setNewbiePrize',
            'toggleDrawer',
            'getGrade',
        ],
        updated(){
             
        },
        computed: {
            computedGameList() {
                return [
                    {
                        result:this.czList
                    },
                    {
                        result:this.sxList
                    },
                    {
                        result:this.qpList
                    },                
                    {
                        result:this.yyList
                    },
                    {
                        result:this.tyList
                    },
                ]
            },
            TEMPLATE2_computedGameList() {
                return [
                    {
                        result:this.czList.slice(1,2)
                    }, 
                    {
                        result:this.czList.slice(2,3)
                    },                
                    {
                        result:this.qpList
                    },
                    {
                        result:this.sxList
                    },
                    {
                        result:this.tyList
                    },
                    {
                        result:this.yyList
                    },
                ]
            },
            currentTime(){
                return new Date().getTime();
            },
            currentDrawing() {
                return this.$store.state.currentDrawing
            },
        },
        mounted() {
            var that=this;
            if (window.localStorage.getItem('user')){
                this.user=window.localStorage.getItem('user');  

                if (localStorage.getItem('user')&&JSON.parse(localStorage.getItem('user')).has_first_money===1) {
                    this.setNewbiePrize();
                } 
            }  

            this.fetchCarousel();
            this.fetchGameList();            
            this.getNotice();

            getAppRedPack().then(result=>{
                if (result.data.code==='SUCCESS') {
                    var data=result.data.result;
                    if (!data || data.length===0) {
                        return false;
                    }
                    this.redPackData.showTime=data.show_start_time*1000
                    this.redPackData.hideTime=data.show_end_time*1000
                    this.redPackData.startTime=data.active_start_time*1000
                    this.redPackData.endTime=data.active_end_time*1000
                    this.redPackData.id=data.id
                    this.redPackData.errorMsg=null
                    this.redPackData.errorMsgId=null
                    this.redPackData.showPack=null
                    this.redPackData.isReceive = parseInt(data.is_receive)
                    this.redPackData.ruleConditions=JSON.parse(data.reward_where)

                    this.redPack_interval=setInterval(function(){
                        that.$forceUpdate();
                    },1000)
                }else {                    
                    app.Mint.Toast(result.data.msg);
                }
            })

            this.$root.$emit('closeBottomTab', false)

            this.$root.$on('hideRule',function(){
                that.ruleShow=false;
            })
            this.CustomScroll(this.$refs.section.id);        
        },
        beforeDestroy() {
            clearInterval(this.redPack_interval);
        },
        beforeRouteLeave(to, from, next){
            this.$root.$emit('closeBottomTab', true)
            next();
        },
    };
</script>
<template>
    <section id="home" :class="[_TEMPLATE,{ locked:this.popupIndex>0 }]" ref='home'>
        <template v-if="_TEMPLATE==='template-1'">
            <Header :mainTitle="header.mainTitle" :headerMenu="header.headerMenu" :backButton="true"></Header>
            <section ref="section" id="section">
                <div class="banner">
                    <swiper :options="swiperOption">
                        <swiper-slide v-for="(item,index) in lunbotuList" :key="index">
                            <a :href="item.url"><img :src="item.img" /></a>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
                <div class="app-message" @click="noticeLike" v-if="notice">
                    <i class="iconfont icon-xiaoxi"></i>
                    <marquee v-if="notice != ''" v-html="notice"></marquee>
                    <p v-else class="animate"></p>
                </div>
                <nav class="featured">
                    <router-link v-for="(item,index) in featured" :key="index" :to="item.url"><img :src="item.iconUrl" />{{item.title}}</router-link>
                </nav>
                <nav id='game-list'>
                    <div id="game-type" ref='gameType'>
                        <nav>
                            <a @click.stop="handleGameType(item)" v-for="item in gameTypeList" v-show='item.show' class="ripple" :class="{active:item.current}"><i class="iconfont" :class="item.icon"></i>{{item.title}}</a>
                        </nav>
                    </div>
                    <template v-for="(item,index) in gameTypeList">
                        <ul class="classic game-by-type" v-if="gameTypeList[index].current">
                            <b v-if="computedGameList[index].result&&computedGameList[index].result.length===0">{{ getWord('no_result_of_game') }}</b>
                            <template v-for="item in computedGameList[index].result">
                                <template v-if="item.list">
                                    <li v-for="item in item.list">
                                        <h5 v-if="item.parent" @click="routerLink(item)">
                                            <img :src="fetchImagePath(item)" />
                                            <span v-if="item.parent">{{item.name}}</span>
                                        </h5>
                                    </li>
                                </template>
                                <template v-else>
                                    <li>
                                        <h5 @click="getWMurl(item)">
                                            <img :src="fetchImagePath(item)" />
                                            <span>{{item.remark}}</span>
                                        </h5>
                                    </li>
                                </template>
                            </template>
                        </ul>
                    </template>
                </nav>
                <p class="bottom-tip">{{ getWord('no_result2') }}</p>
            </section>
        </template>
        <template v-if="_TEMPLATE==='template-2'">
            <Header ref="header2"
                :userInfo="user"
                :signNavTag="false" 
                :headerMenu="header.headerMenu" 
                :backButton="true" 
                :logoUrl="header.logoUrl" 
            />
            <section ref="section" id="section">
                <div class="banner">
                    <swiper :options="swiperOption2">
                        <swiper-slide v-for="(item,index) in lunbotuList" :key="index">
                            <a :href="item.url"><img :src="item.img" /></a>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
                <div class="app-message" v-if="appMessageClosed&&notice">
                    <img :src="require('@@/assets/images/home/icons/message.png')" class="msg" />
                    <marquee v-if="notice != ''" v-html="notice" @click="noticeLike"></marquee>
                    <p v-else class="animate"></p>
                    <img :src="require('@@/assets/images/home/icons/close.png')" class="close" @click="appMessageClosed=false" />
                </div>
                <nav class="featured">
                    <router-link v-for="(item,index) in featured2" :key="index" :to="item.url">
                        <img :src="item.attachedIconUrl" class="animate a1" v-if="item.attachedIconUrl" />
                        <img :src="item.iconUrl" class="base" />
                        {{item.title}}
                    </router-link>
                </nav>
                <nav id='game-list' v-if="czList">
                    <template v-for="item in czList.slice(0,1)">
                        <h3 v-if="item.type!=='systematic'">{{item.name}}</h3>
                        <ul :class="{ 
                            'pc28':item.type && item.type.indexOf('pc28')>-1,
                            'clearfix':item.type && item.type.indexOf('pc28')>-1, 
                            'less-1': item.list.length===4,
                            'less-2': item.list.length===3,
                            'less-3': item.list.length===2,
                            'less-4': item.list.length===1,
                            'less-5': item.list.length===0,
                        }" class="tba1">
                            <li v-for="_item in item.list" :style="renderBg(_item)" @click="routerLink(_item)">
                                <h5 v-if="_item.parent">
                                    <span v-if="_item.parent">{{_item.name}}</span>
                                    <span v-if="_item.parent && _item.parent.toLowerCase().indexOf('pc28')>-1">
                                        {{ pc28Time[_item.type].closed?getWord('handicap_closed'):formatCountdownTimer(pc28Time[_item.type].current) }}
                                    </span>
                                </h5>
                            </li>
                            <li v-if="item.list.length===4" class="more">
                                <h5>
                                    <span>{{ getWord('coming_soon') }}...</span>
                                </h5>
                            </li>
                        </ul>
                    </template>
                    <template v-for="(_item,_index) in gameTypeList2" v-if="_item.show">
                        <h3>{{_item.title}}</h3>
                        <ul class="classic game-by-type" :class="{block:_item.icon.indexOf('shixun')>-1 || _item.icon.indexOf('tiyu')>-1 || _item.icon.indexOf('youyi')>-1}">
                            <b v-if="TEMPLATE2_computedGameList[_index].result.length===0">{{ getWord('no_result_of_game') }}</b>
                            <template v-for="(item,index) in TEMPLATE2_computedGameList[_index].result">
                                <template v-if="item.list">
                                    <li v-for="(item,index) in item.list.slice(0,7)">
                                        <h5 v-if="item.parent" @click="routerLink(item)">
                                            <img :src="fetchGameIconPath(item)" />
                                            <span v-if="item.parent">{{item.name}}</span>                         
                                        </h5>
                                    </li>

                                    <li v-if="TEMPLATE2_computedGameList[_index].result[0].list&&TEMPLATE2_computedGameList[_index].result[0].list.length>6">
                                        <h5 @click="routerLink({
                                            type:'more-cz'
                                        })">
                                            <img :src="imgUrlTitle+cloudBaseFolder+'/home/game-list/more-cz.png'" />
                                            <span>{{ getWord('more_games') }}</span>                         
                                        </h5>
                                    </li>
                                </template>

                                <template v-else>
                                    <li :style="renderBg(item,index+1)">
                                        <h5 @click="getWMurl(item)">
                                            <img v-if="item.type=='2'" :src="item.img" />
                                            <span>{{item.remark}}</span>
                                        </h5>
                                    </li>
                                </template>

                                <template v-if="TEMPLATE2_computedGameList[_index].result.length%2!==0&&index===TEMPLATE2_computedGameList[_index].result.length-1&&(_item.icon.indexOf('shixun')>-1 || _item.icon.indexOf('tiyu')>-1 || _item.icon.indexOf('youyi')>-1)">
                                    <li :style="renderBgN(item)">
                                        <h5>
                                            <span>{{ getWord('coming_soon') }}</span>
                                        </h5>
                                    </li>
                                </template>
                            </template>                        
                        </ul>
                    </template>
                </nav>
                <p class="bottom-tip">{{ getWord('no_result3') }}</p>
            </section>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header 
                :userInfo="user"
                :mainTitle="header.mainTitle" 
                :headerMenu="header.headerMenu" 
                :backButton="true" 
                :toggleDrawer="toggleDrawer"
                :signNavTag=true
            />
            <section ref="section" id="section">
                <div class="banner">
                    <swiper :options="swiperOption">
                        <swiper-slide v-for="(item,index) in lunbotuList" :key="index">
                            <a :href="item.url"><img :src="item.img" /></a>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
                <div class="app-message" @click="noticeLike" v-if="notice">
                    <h5>{{ getWord('announcement') }}</h5>
                    <marquee v-if="notice != ''" v-html="notice"></marquee>
                    <p v-else class="animate"></p>
                </div>
                <nav class="featured">
                    <router-link v-for="(item,index) in featured3" :key="index" :to="item.url" :style="{'--data-color':item.color}"><img :src="item.iconUrl" />{{item.title}}</router-link>
                </nav>
                <h3>{{ getWord('auctioneer_by_ui') }} <span>LOTTERY</span></h3>
                <nav id='game-list' v-if="czList">
                    <div id="game-type" v-if="!(yyList.length===0 && tyList.length===0 && sxList.length===0 && qpList.length===0)">
                        <nav>
                            <a @click.stop="handleGameType(item)" v-for="item in gameTypeList" v-show='item.show' class="ripple" :class="{active:item.current}">
                                {{item.title.slice(0,2)}}
                            </a>
                        </nav>
                    </div>
                    <template v-for="(item,index) in gameTypeList">
                        <ul class="classic game-by-type" v-if="gameTypeList[index].current">
                            <b v-if="computedGameList[index].result.length===0">{{ getWord('no_result_of_game') }}</b>
                            <template v-for="item in computedGameList[index].result">
                                <template v-if="item.list">
                                    <li v-for="item in item.list">
                                        <h5 v-if="item.parent" @click="routerLink(item)">
                                            <img :src="fetchGameIconPath(item)" />
                                            <span v-if="item.parent">{{item.name}}</span>
                                        </h5>
                                    </li>
                                </template>
                                <template v-else>
                                    <li>
                                        <h5 @click="getWMurl(item)">
                                            <img :src="fetchImagePath(item)" />
                                            <span>{{item.remark}}</span>
                                        </h5>
                                    </li>
                                </template>
                            </template>
                        </ul>
                    </template>
                </nav>  
            </section>
        </template>
        <aside class="red-envelope ready" v-if="new Date().getTime() > redPackData.showTime && new Date().getTime() < redPackData.startTime && (redPackData.isReceive !== 1)" @click="openRedPack()">
            <img :src="require('@@/assets/images/home/red-envelope/ready.png')" />
            <span class="time" v-if="redPackData.startTime-new Date().getTime()<(24*60*60*1000)">{{ timeFilter(redPackData.startTime) }}</span>
            <span class="time" v-else>{{ getWord('red_envelope_game') }}</span>
        </aside>
        <aside class="red-envelope start" v-if="new Date().getTime() > redPackData.startTime && new Date().getTime() < redPackData.hideTime && !redPackData.earnedAmount && (redPackData.isReceive !== 1)" @click="openRedPack()">
            <img :src="require('@@/assets/images/home/red-envelope/start.gif')" />
        </aside>
        <aside class="red-envelope pre-open" v-if="(redPackData.errorMsgId && redPackData.errorMsgId==='RED_ACTIVITY_START') && new Date().getTime() < redPackData.startTime">
            <h4>{{ getWord('red_envelope_tips4') }}<br />
                <span>{{ getWord('timedown') }}:<i>{{ timeFilter(redPackData.startTime,true) }}</i></span>
            </h4>
            <h5 @click='showRule()'>{{ getWord('event_rules') }}</h5>
            <i class="iconfont2 icon-close2" @click="closeRedPack()"></i>
            <img :src="require('@@/assets/images/home/red-envelope/pre-open.png')" />
        </aside>
        <aside class="red-envelope open" v-if="redPackData.id && redPackData.earnedAmount">
            <h4>{{ getWord('congratulations') }}<br />
                <span>{{ redPackData.earnedAmount }}<i>{{ currencySymbol() }}</i></span>
            </h4>
            <h5 @click='showRule()'>{{ getWord('event_rules') }}</h5>
            <i class="iconfont2 icon-close2" @click="closeRedPack()"></i>
            <img :src="require('@@/assets/images/home/red-envelope/open.png')" />
        </aside>
        <aside class="red-envelope opened" v-if="redPackData.errorMsgId && redPackData.errorMsgId!=='RED_ACTIVITY_START'" :class="{'long':redPackData.errorMsgId==='RED_GRABBED'}">
            <h4 v-if="redPackData.errorMsgId==='RED_RECEIVED'||redPackData.errorMsgId==='RED_ACTIVITY_START'||redPackData.errorMsgId==='RED_ACTIVITY_FISH'">{{ getWord('unfortunately') }}!</h4>
            <h4 v-if="redPackData.errorMsgId==='RED_INVALID'||redPackData.errorMsgId==='RED_GRABBED'||redPackData.errorMsgId==='RED_CONDTION'||redPackData.errorMsgId==='RED_CONDTION_TIME'||redPackData.errorMsgId==='RED_CONDTION_CONSUMED'">{{ getWord('embarrassed') }}!</h4>
            <p>{{ redPackData.errorMsg }}</p>
            <h5 @click='showRule()'>{{ getWord('event_rules') }}</h5>
            <i class="iconfont2 icon-close2" @click="closeRedPack()"></i>
            <img :src="require('@@/assets/images/home/red-envelope/pre-open.png')" v-if="redPackData.errorMsgId!=='RED_GRABBED'" />
            <img :src="require('@@/assets/images/home/red-envelope/open-failed.png')" v-if="redPackData.errorMsgId==='RED_GRABBED'" />
        </aside>
        <RedEnvelopeRule :redPackData="redPackData" v-if="ruleShow" />
    </section>
</template>
<style lang='scss' scoped>
#home {
    background-color: #f3f3f4;
    overflow: auto;
    display: flex;
    flex-direction: column;

    > section {
        overflow: auto;
    }

    > .red-envelope {
        position: fixed;
        top:50%;
        right: 0;
        z-index: 11;

        img {
            max-width: 100%;
        }

        &.ready {
            width: 1rem;

            .time {
                font-size: .2rem;
                color: #FF0F0F;
                position: absolute;
                bottom: .1rem;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }

        &.start {
            width: 1rem;
        }

        &.open,
        &.pre-open,
        &.opened {
            width: 6rem;
            margin: auto;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            height: 8.3rem;

            &:before {
                content:'';
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.8);
                margin: auto;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                position: fixed;
                z-index: -1;
            }
        }

        &.open {
            height: 9.7rem;

            h4 {
                top: 4.5rem;

                span {
                    display: inline-block;
                    margin-top: .25rem;
                    color: #FCEF56;
                    font-size: .98rem;

                    i {
                        font-size: .4rem;
                    }
                }
            }
        }

        &.long {
            height: 9.7rem;

            h4 {
                top: 4.5rem;
            }

            p {
                top: 5.5rem;
            }
        }

        h4,
        p,
        h5 {
            color: #FBD49E;            
            font-weight: normal;
            position: absolute;            
            text-align: center;
            width: 100%;
            box-sizing: border-box;
        }

        h4 {
            top: 3.5rem;
            font-size: .48rem;
        }

        p {
            color: #FBD49E;
            font-size: .36rem;
            top: 4.5rem;
            padding: 0 .25rem;
        }

        h5 {
            color: #911818;
            font-size: .28rem;
            bottom: .5rem;
        }

        .icon-close2 {
            color: #C2C2C2;
            font-size: .28rem;            
            font-weight: normal;
            position: absolute;
            bottom: -1rem;
            text-align: center;
            border: 1px solid #C2C2C2;
            border-radius: 100%;
            padding: .1rem;
            margin: 0 auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &.template-3 {
        background-color: #EEEEEE;

        #section {
            height: 100%;
        }

        .banner {

            &:before {
                content:none;
            }
        }

        .app-message {
            margin-bottom: .1rem;
            align-items: baseline;

            h5 {
                font-size: .3rem;
                font-weight: normal;
                white-space: nowrap;
                color: #EC2829;
                border: 1px solid #EC2829;
                border-radius: 3px;
                padding: .025rem .1rem;
                margin-right: .2rem;
            }
        }

        .featured {
            margin:0 0 .1rem 0;
            border-radius: 0;
            padding: .1rem 0;
            display: flex;

            a {
                flex-basis: 25%;
                font-size: .3rem;
                color: var(--data-color);
            }
        }

        .swiper-container {

            .swiper-wrapper {

                .swiper-slide {
                    height: 3.2rem;
                }
            }
        }

        h3 {
            color: #A69393;
            font-size: .36rem;
            font-weight: normal;
            padding: .1rem .25rem;
            margin-bottom: .1rem;

            span {
                color: #CCCCCC;
            }
        }

        #game-list {

            #game-type {
                border-bottom: 0;

                nav {
                    height: .8rem;

                    a {
                        font-size: .36rem;
                        width: 20%;
                        line-height: .8rem;                 
                        border-bottom:2px solid transparent;
                        box-sizing: border-box;
                        height: .8rem;       

                        &.active {
                            color: #EC2829;
                            border-bottom:2px solid #EC2829;
                        }
                    }
                }
            }

            ul {
                background-color: transparent;
                padding: .1rem;

                &.classic {

                    li {
                        width: 33.3%;
                        padding: 0 .05rem;
                        margin-bottom: .1rem;
                        box-sizing: border-box;
                    }
                }

                li {

                    h5 {
                        background-color: #ffffff;
                        padding: .1rem 0;
                        border-radius: 6px;
                        overflow: hidden;

                        span {
                            white-space: nowrap;
                        }

                        img {
                            width: 1.08rem;
                            height: 1.08rem;
                        }
                    }
                }
            }
        }
    }

    &.template-2 {

        .app-message {
            margin-bottom: .1rem;

            img.msg {
                height: 0.5rem;
                margin-top: .1rem;
                padding-right: .2rem;
            }

            img.close {
                height: .3rem;
                padding-top: .2rem;
                padding-left: .2rem;
                float: right;
            }
        }

        .featured {
            margin: 0;
            border-radius: 0;
            position: relative;
            z-index: 1;

            a {
                position: relative;
                flex-basis: 25%;

                img {
                    height: 1rem;
                    width: 1rem;

                    &.animate {
                        width: .5rem;
                        height: .5rem;
                        margin-left: 50%;
                        left: -12.5%;
                        top: .2rem;
                        opacity: 1;
                        position: absolute;
                        animation: downloadbounce 3s infinite ease;
                        
                    }

                    @keyframes downloadbounce {

                        from,
                        20%,
                        53%,
                        80%,
                        to {
                            animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
                            transform: translate3d(0, 0, 0);
                        }

                        40%,
                        43% {
                            animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
                            transform: translate3d(0, -.275rem, 0);
                        }

                        70% {
                            animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
                            transform: translate3d(0, -.25rem, 0);
                        }

                        90% {
                            transform: translate3d(0, -.08rem, 0);
                        }
                    }
                }
            }
        }

        #game-list {            

            h3 {
                background-color: #ffffff;
                margin-top: .1rem;
                padding-top: .1rem;
                color: #000000;
                font-weight: 400;
                font-size: .4rem;

                &:before {
                    content:none;
                }
            }

            ul {

                &.classic {

                    li {
                        border: 1px solid #f3f3f0;
                        box-sizing: border-box;
                        padding: .1rem;
                        margin-bottom: -1px;
                    }
                }

                &.pc28 {
                    justify-content: space-between;
                    display: block;
                    padding-top: .1rem;

                    &.less-2 {

                        li {

                            &:nth-child(n+2) {
                                height: 2.9rem;
                            }
                        }
                    }

                    &.less-3 {
                        display: flex;

                        &:after {
                            content: none;
                        }

                        li {

                            &:nth-child(1) {
                                width: 48%;
                            }

                            &:nth-child(n+2) {
                                width: 48%;
                                height: 2.9rem;
                            }
                        }
                    }

                    &.less-4 {

                        li {

                            &:nth-child(1) {
                                width: 100%;
                            }
                        }
                    }

                    &.tba {
                        display: flex;

                        &.less-4 {

                            li {

                                &:nth-child(2) {
                                    width: 48%;
                                }
                            }
                        }

                        &:after {
                            content: none;
                        }
                        
                        li {
                            width: 48%;

                            &:nth-child(1) {
                                display: block;
                            }
                        }
                    }

                    li {
                        width: 35%;
                        height: 2.9rem;
                        padding:.1rem .2rem;
                        background-position: right;
                        float: left;

                        &.more {
                            background: rgb(161,186,254);
                            background: linear-gradient(90deg, rgba(161,186,254,1) 0%, rgba(123,178,249,1) 100%);

                            h5 {
                                padding-top: .33rem;
                            }
                        }

                        // &:nth-child(1) {
                        //     display: none;
                        // }

                        &:nth-child(n+2) {                            
                            margin-left: 1.5%;
                            width: 31%;
                            height: 1.4rem;
                            padding: .1rem;
                        }

                        &:nth-child(4),
                        &:nth-child(5) {
                            margin-top: .1rem;
                        }

                        &.pc28-more {
                            width: 1.8rem;
                            color: #ffffff;
                            text-align: center;
                            line-height: 1.3rem;
                            background: rgb(144,88,255);
                            background: linear-gradient(90deg, rgba(144,88,255,1) 0%, rgba(112,160,255,1) 100%);
                            font-size: .3rem;
                        }

                        h5 {
                            font-size: .3rem;
                        }
                    }
                }

                &.block {
                    justify-content: space-between;
                    padding: 0.05rem 0.1rem;

                    li {
                        width: 49.5%;
                        height: 1rem;
                        margin-bottom: 0.1rem;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: right top;
                        border: 0;
                        box-sizing: content-box;
                        padding: 0;

                        h5 {
                            color: var(--data-color);
                            text-align: left;
                            padding: .25rem 0 0 .3rem;
                            font-size: .4rem;
                            img {
                                display: none;
                            }
                        }
                    }
                }

                li {
                    overflow: hidden;

                    h5 {

                        span {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }

    &.locked {
        pointer-events: none;
    }

    .banner {
        background-color: #ffffff;
        position: relative;

        &:before {
            content: '';
            border-bottom: .825rem solid #3985E1;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            border-radius: 0 0 50% 50%;
        }
    }

    .app-message {
        display: flex;
        padding: 0 .2rem;
        background-color: #ffffff;
        margin-bottom: .25rem;

        >i {
            font-size: .4rem;
            margin-top: .125rem;
            color: #f4973d;
        }

        marquee {
            line-height: .7rem;
            color: #E83535;
            font-size: .3rem;
            flex-grow: 1;
        }
    }

    .featured {
        background-color: #ffffff;
        display: flex;
        justify-content: space-around;
        padding: .25rem 0;
        margin: .33rem 0;
        margin-bottom: .25rem;
        border-radius: .15rem;

        a {
            text-align: center;
            font-size: .24rem;

            img {
                display: block;
                margin: 0 auto;
                width: .44rem;
                height: .44rem;
                margin-bottom: .1rem;
            }
        }
    }

    #game-list {
        margin-bottom: .25rem;

        h3 {
            font-size: .32rem;
            font-weight: 500;
            color: #666666;
            line-height: .7rem;
            padding: 0 .2rem;

            &:before {
                content: '';
                width: .1rem;
                background-color: #3985E1;
                height: .32rem;
                display: inline-block;
                vertical-align: middle;
                margin-right: .25rem;
                border-radius: 1rem;
            }
        }

        #game-type {
            overflow-x: auto;
            overflow-y: hidden;
            background-color: #ffffff;
            border-bottom: 1px solid #E7E5E5;

            nav {
                white-space: nowrap;

                >a {
                    font-size: .26rem;
                    width: 30%;
                    display: inline-block;
                    line-height: 1rem;
                    text-align: center;

                    &.active {
                        color: rgba(57, 133, 225, 1);
                    }

                    .iconfont {
                        margin-right: .1rem;
                    }
                }
            }
        }

        ul {
            display: flex;
            padding:.1rem .2rem .2rem .2rem;
            background-color: #ffffff;
            flex-wrap: wrap;

            &.classic {

                li {
                    width: 25%;
                    margin-bottom: .2rem;
                }
            }

            &.third {
                justify-content: space-between;

                li {
                    width: 49%;
                    margin-bottom: 2%;
                }
            }

            &.system {
                padding-top: 0;
                padding-bottom: 0;

                li {
                    width: 25%;
                    margin-bottom: .2rem;
                }
            }

            &.pc28 {
                justify-content: space-around;

                li {
                    width: 3.35rem;
                    height: 1.72rem;
                    background-size: cover;
                    box-sizing: border-box;
                    padding: 0.5rem 0 0 .5rem;

                    h5 {
                        font-size: .28rem;
                        color: #ffffff;
                        text-align: left;

                        span {
                            display: block;
                            font-weight: 500;
                        }
                    }

                    &:nth-child(2) {
                        filter: hue-rotate(20deg);
                    }
                }
            }

            li {

                h5 {
                    text-align: center;
                    color: #333333;
                    font-size: .28rem;
                    font-weight: 400;

                    img {
                        display: block;
                        width: .88rem;
                        height: .88rem;
                        margin: 0 auto;
                        margin-bottom: .1rem;
                    }
                }
            }

            b {
                font-weight: normal;
                text-align: center;
                font-size: .28rem;
                display: inline-block;
                margin: 0 auto;
            }
                        }
                    }

    .bottom-tip {
        font-size: .24rem;
        color: #a8a8a8;
        text-align: center;
        margin-bottom: .25rem;
    }
}

.thirdPart {
    border: 1px solid #eee;

    img {
        padding: 1px;
        width: 3.44rem !important;
        height: 1.65rem !important;
    }
}

ul li {
    list-style: none;
}
</style>
<style lang='scss' type="text/css">
.swiper-container {

    .swiper-wrapper {

        .swiper-slide {
            height: 150px;
            overflow: hidden;

            img {
                max-width: 100%;
            }

            &.swiper-slide-active {

                img {
                    transform: scale(1);
                }
            }
        }
    }

    .swiper-pagination {
        margin-bottom: .25rem;

        .swiper-pagination-bullet {
            outline: 0;
            margin: 0 .125rem;

            &.swiper-pagination-bullet-active {
                background: #ffffff;
                height: 6px;
                width: 12px;
                border-radius: 4px;
                top: -1px;
            }
        }
    }
}
</style>